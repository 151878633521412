import './index.scss'
import { useEffect, useState } from 'react'
import { removeToken, setToken } from '@/lib/cookies'
import userStore from '@/store/user'
import { observer } from 'mobx-react'
import Image from 'next/image'
import icon_closure from '@/assets/image/close.png'
import { message } from 'antd'
import { useRouter } from 'next/navigation'
interface EventT {
  data: any
  origin: string
}
function Login() {
  const router = useRouter()
  const [showPage, setShowPage] = useState(false)
  function getIFrameUrl() {
    return `${process.env.NEXT_PUBLIC_SSO}?title=产业链地图&url=${process.env.NEXT_PUBLIC_PROJECT}`
  }
  let [showClose, setShowClose] = useState<boolean>(true)
  const handleCloseClick = () => {
    userStore.setShowLogin(false)
  }
  useEffect(() => {
    setShowPage(true)
    window.addEventListener('message', function (event: EventT) {
      if (event.origin === process.env.NEXT_PUBLIC_SSO) {
        // 处理来自SSO单点登录的消息
        if (typeof event.data === 'string' && event.data !== '') {
          if (event.data.indexOf('token=') !== -1) {
            setToken(event.data.split('token=')[1])
            userStore.setShowLogin(false)
            userStore.setUserInfo()
          }
        } else if (Object.prototype.hasOwnProperty.call(event.data, 'protocol')) {
          showClose = event.data.protocol === true
          setShowClose(showClose)
        } else if (Object.prototype.hasOwnProperty.call(event.data, 'logout')) {
          if (event.data.logout === true) {
            removeToken()
            userStore.removeUserInfo()
            setTimeout(() => {
              window.location.reload()
            })
          } else {
            message.error('退出失败，请再次尝试')
          }
        }
      }
    })
  }, [])
  return (
    <div>
      {showPage ? (
        <div
          onClick={() => userStore.setShowLogin(false)}
          style={{ display: !userStore.showLogin ? 'none' : '' }}
          className={`iframe-box`}
        >
          <iframe id="iframeId" src={getIFrameUrl()} />
          {showClose ? (
            <Image
              onClick={handleCloseClick}
              src={icon_closure}
              width={28}
              height={28}
              className={`close`}
              alt=""
            />
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default observer(Login)
