import { makeAutoObservable } from 'mobx'
import { getUserInfo, configListBefore } from '@/api/login'
import { getUnreadNum } from '@/api/message'
class UserStore {
  constructor() {
    makeAutoObservable(this)
  }
  userInfo: any = null
  imgUrl = ''
  unReadNum = 0
  showLogin = false //是否显示登录弹框
  setUserInfo = async () => {
    const data = (await getUserInfo()) as any
    if (data.resp_code === 0) {
      this.userInfo = data.datas
    }
  }
  removeUserInfo = () => {
    this.userInfo = null
  }

  setImgUrl = async () => {
    const value = process.env.NEXT_PUBLIC_CONFIG as any
    const data = (await configListBefore(value)) as any
    if (data.resp_code === 0) {
      this.imgUrl = data.datas[0].url
    }
  }
  setShowLogin = (flag: boolean) => {
    this.showLogin = flag
  }
  setUnRead = async () => {
    const data = await getUnreadNum()
    if (data.resp_code === 0) {
      this.unReadNum = data.datas
    }
  }
}
const userStore = new UserStore()
export default userStore
