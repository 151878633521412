import { makeAutoObservable } from 'mobx'
class EnterpriseStore {
  constructor() {
    makeAutoObservable(this)
  }
  useEnterprise = null
  useMapCenter = [104.5, 35.44]
  useMapZoom = 4.9
  useEnterpriseDetail = {}
  useGuideStep = -1
  useApplyingCooperationEnterprise: number | null = null //想申请合作的企业id
  useBackMapZoomCenter = {
    zoom: this.useMapZoom,
    center: [104.5, 35.44],
  }
  useEnterpriseParams = {
    regionCodes: ['156'],
    sysDictIds: [],
    searchBox: '',
  }
  setBackMapZoomCenter = (data: any) => {
    this.useBackMapZoomCenter = data
  }
  setEnterpriseDetail = (data: any) => {
    this.useEnterpriseDetail = data
  }
  setEnterprise = (data: any) => {
    this.useEnterprise = data
  }
  setMapCenter = (data: any) => {
    this.useMapCenter = data
  }
  setMapZoom = (data: any) => {
    this.useMapZoom = data
  }
  setEnterpriseParams = (data: any) => {
    this.useEnterpriseParams = { ...data }
  }
  setUseGuideStep = (step: number) => {
    this.useGuideStep = step
  }
  setUseApplyingCooperationEnterprise = (id: number | null) => {
    this.useApplyingCooperationEnterprise = id
  }
}
const enterpriseStore = new EnterpriseStore()
export default enterpriseStore
