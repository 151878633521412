'use client'
import React, { useEffect } from 'react'
import { usePathname } from 'next/navigation'
import { observer } from 'mobx-react'
import { useStore } from '@/store/index'
import { getToken } from '@/lib/cookies'
import { useRouter } from 'next/navigation'
import { aplusQueue } from '@/lib/SDK'
import { ConfigProvider } from 'antd'
import { StyleProvider } from '@ant-design/cssinjs'
import LoginBox from '@/components/Login/index'
import '@/style/globals.css'
import '@/style/ant.scss'
import MobileHint from '@/components/Modal/mobileHint'
function RootLayout({ children }: { children: React.ReactNode }) {
  const routerName = usePathname()
  const { userStore } = useStore()
  let router = useRouter()
  //集成埋点
  const sendSDK = () => {
    aplusQueue(window, 'script', 'aplus_queue', '203467608')
    const { aplus_queue } = window as any
    //集成应用的appKey
    aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: ['appKey', '64d07f3fbd4b621232e9e1e8'],
    })

    aplus_queue.push({
      action: 'aplus.sendPV',
      arguments: [
        {
          is_auto: false,
        },
        {
          'aplus-rhost-v': window.location.hostname,
        },
      ],
    })
  }

  useEffect(() => {
    let widthRouter = ['/uploadEnterprise', '/personCenterPage']
    const token = getToken()
    userStore.setImgUrl()
    if (token) {
      if (!userStore.userInfo || Object.keys(userStore.userInfo).length === 0)
        userStore.setUserInfo()
      userStore.setUnRead()
    } else {
      userStore.removeUserInfo()
      if (widthRouter.indexOf(routerName) !== -1) {
        router.replace('/home')
      }
    }
    if (routerName == '/') {
      router.replace('/home')
    }
    if (routerName == '/') {
      router.replace('/home')
    }
  }, [routerName])
  useEffect(() => {
    sendSDK()
    sessionStorage.removeItem('reloaded')
  }, [])
  return (
    <html lang="en">
      <body>
        <StyleProvider hashPriority="high">
          <ConfigProvider
            theme={{
              components: {
                Pagination: {
                  itemActiveBg: '#244BF1',
                  itemSize: 32,
                },
              },
            }}
          >
            <div style={{ overflow: 'auto' }}>{children}</div>
          </ConfigProvider>

          <MobileHint />
          <LoginBox />
        </StyleProvider>
      </body>
    </html>
  )
}

export default observer(RootLayout)
